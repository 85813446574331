import React, { useState, useEffect } from "react";
import { LuMenu } from "react-icons/lu";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import PROTOHEIM_LOGO from "../assets/PROTOHEIM_LOGO.png";
import PROTOHEIM_ from "../assets/PROTOHEIM_.png";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const currentPath = location.pathname;

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getLinkStyle = (path, label) => {
    const activeStyle = {
      color: "#283891",
      padding: "0.5rem 1rem",
      borderRadius: "50px",
      backgroundColor: "white",
    };

    const inactiveStyle = {
      color: "white",
      padding: "0.5rem 1rem",
      borderRadius: "50px",
      backgroundColor: "transparent",
    };

    const primaryColorStyle = {
      color: "#283891",
      padding: "0.5rem 1rem",
      borderRadius: "50px",
      backgroundColor: "white",
    };

    const blackColorStyle = {
      color: "black",
      padding: "0.5rem 1rem",
      borderRadius: "50px",
      backgroundColor: "transparent",
    };

    if (window.innerWidth > 1024) {
      if (currentPath === "/about" && label === "About") {
        return primaryColorStyle;
      }
      if (currentPath === "/services" && label === "Service") {
        return primaryColorStyle;
      }
      if (currentPath === "/products" && label === "Portfolio") {
        return primaryColorStyle;
      }
    }

    if (["/about", "/services", "/products"].includes(currentPath)) {
      return blackColorStyle;
    }

    return currentPath === path ? activeStyle : inactiveStyle;
  };

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <header
      style={{
        width: "100%",
        position: "absolute",
        top: "0",
        zIndex: "10",
        backgroundColor: isMobile ? "#ffffff" : "transparent",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1rem",
          maxWidth: "1440px",
          margin: "0 auto",
        }}
      >
        {/* Logo */}
        <Link
          to="/"
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src={PROTOHEIM_LOGO}
            alt="PROTOHEIM Logo"
            style={{
              height: window.innerWidth > 1024 ? "40px" : "24px",
            }}
          />
          <img
            src={PROTOHEIM_}
            alt="PROTOHEIM Additional Logo"
            style={{
              height: window.innerWidth > 1024 ? "32px" : "26px",
              marginLeft: "8px",
            }}
          />
        </Link>

        {/* Desktop Menu */}
        {!isMobile && (
          <nav
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {[
              { path: "/", label: "Home" },
              { path: "/about", label: "About" },
              { path: "/services", label: "Service" },
              // { path: "/products", label: "Portfolio" },
              { path: "/contactus", label: "Contact" },
            ].map((link) => (
              <Link
                key={link.path}
                to={link.path}
                style={{
                  ...getLinkStyle(link.path, link.label),
                  fontFamily: "Gilroy-Bold",
                  fontSize: "15px",
                  fontWeight: "400",
                  lineHeight: "26px",
                  textAlign: "center",
                  textDecoration: "none",
                }}
              >
                {link.label}
              </Link>
            ))}
          </nav>
        )}

        {/* Mobile Menu Button */}
        {isMobile && (
          <button
            onClick={toggleMenu}
            aria-label={isOpen ? "Close menu" : "Open menu"}
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "#283891",
              cursor: "pointer",
            }}
          >
            {isOpen ? (
              <IoCloseCircleOutline size={24} />
            ) : (
              <LuMenu size={28} />
            )}
          </button>
        )}
      </div>

      {/* Mobile Menu */}
      {isOpen && isMobile && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            height: "100vh",
            width: "100vw",
            backgroundColor: "#ffffff",
            color: "#283891",
            zIndex: "50",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IoCloseCircleOutline
            onClick={() => setIsOpen(false)}
            size={24}
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              color: "#283891",
              cursor: "pointer",
            }}
          />
          <div className="flex flex-col items-center justify-center h-full">
            <div className="flex items-center mb-3">
              <img
                src={PROTOHEIM_LOGO}
                alt="PROTOHEIM Logo"
                className="object-contain w-8"
              />
              <img
                src={PROTOHEIM_}
                alt="PROTOHEIM Additional Logo"
                style={{
                  width: "80px",
                  objectFit: "contain",
                  marginLeft: "8px",
                }}
              />
            </div>
            {[
              { path: "/", label: "Home" },
              { path: "/about", label: "About" },
              { path: "/services", label: "Service" },
              // { path: "/products", label: "Portfolio" },
              { path: "/contactus", label: "Contact" },
            ].map((link) => (
              <Link
                key={link.path}
                to={link.path}
                style={{
                  color: "#283891",
                  marginBottom: "0.5rem",
                  display: "block",
                  fontSize: "18px",
                  textDecoration: "none",
                }}
              >
                {link.label}
              </Link>
            ))}
            <Link to="/contactus">
              <button
                type="button"
                style={{
                  padding: "8px 16px",
                  borderRadius: "5px",
                  backgroundColor: "#283891",
                  color: "white",
                  border: "none",
                  cursor: "pointer",
                  fontSize: "14px",
                }}
              >
                Contact
              </button>
            </Link>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
